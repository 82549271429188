import { useEffect, useState } from 'react';
import style from './Preloader.module.scss';

const Preloader = () => {
  // const [isLoad, setLoad] = useState(false);
  // const body = document.querySelector('body');

  // useEffect(() => {
  //   body.classList.add('lock');

  //   // window.addEventListener('load', () => {
  //   // });
  //   const timer = setTimeout(() => {
  //     body.classList.remove('lock');
  //     setLoad(true);
  //   }, 3500);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    // <div className={`${style.preloader} ${isLoad && style.loaded}`}>
    <div className={`${style.preloader}`}>
      {/* <div class={style.wrapper}>
        <div class={style.circle}></div>
        <div class={style.circle}></div>
        <div class={style.circle}></div>
        <div class={style.shadow}></div>
        <div class={style.shadow}></div>
        <div class={style.shadow}></div>
      </div> */}
      <div class={style.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Preloader;
