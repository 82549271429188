export const LinksArray = [
  {
    name: 'Home',
    to: '/',
  },
  // {
  //   name: 'corporate solution',
  //   to: '',
  // },
  // {
  //   name: 'blog',
  //   to: '',
  // },
  {
    name: 'Asset Managers',
    to: 'asset-managers',
  },
  {
    name: 'Careers',
    to: 'careers',
  },
  // {
  //   name: 'contact',
  //   to: '',
  // },
];
