import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// import HomePage from './pages/Home/Index';
import Header from './components/Header/Index';
import Footer from './components/Footer/Index';
import Fluid from './lib/fluid';
import style from './Index.module.scss';
import viewportUnitsBuggyfill from 'viewport-units-buggyfill';
import { useLocalStorage } from 'usehooks-ts';
import Preloader from './components/Preloader';

const HomePage = React.lazy(() => import('./pages/Home/Index'));
const ManagersPage = React.lazy(() => import('./pages/Managers'));
const CareersPage = React.lazy(() => import('./pages/Careers'));
const PrivacyPage = React.lazy(() => import('./pages/Privacy'));

function App() {
  const [isLoad, setLoad] = useState(false);
  const location = useLocation();
  const [isImgLoaded, setImgLoaded] = useLocalStorage('imgLoaded', false);
  const body = document.querySelector('body');

  useEffect(() => {
    document.addEventListener('DOMContentLoaded', function () {
      var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

      if ('IntersectionObserver' in window) {
        var lazyVideoObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (video) {
            if (video.isIntersecting) {
              for (var source in video.target.children) {
                var videoSource = video.target.children[source];
                if (
                  typeof videoSource.tagName === 'string' &&
                  videoSource.tagName === 'SOURCE'
                ) {
                  videoSource.src = videoSource.dataset.src;
                }
              }

              video.target.load();
              video.target.classList.remove('lazy');
              lazyVideoObserver.unobserve(video.target);
            }
          });
        });

        lazyVideos.forEach(function (lazyVideo) {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    });
  }, []);

  useEffect(() => {
    viewportUnitsBuggyfill.init({ force: true });
  }, []);

  // useEffect(() => {
  //   // const videoArr = document.querySelectorAll('video');
  //   body.classList.add('lock');

  //   // console.log(videoArr);

  //   // window.addEventListener('load', () => {
  //   // });
  //   const timer = setTimeout(() => {
  //     body.classList.remove('lock');
  //     setLoad(true);
  //   }, 3500);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div className={`wrapper`}>
      {/* {isLoad && (
      )} */}
      <Fluid className={`${location.pathname !== '/' && style.hidden}`} />

      {/* {!isLoad && <Preloader />} */}
      {/* {!isLoad ? (
        <Preloader />
      ) : (
      )} */}
      <div className={`${style.content} content`}>
        <Suspense fallback={<Preloader />}>
          <Header />

          <Routes>
            <Route path='*' element={<HomePage />} />
            <Route path='asset-managers' element={<ManagersPage />} />
            <Route path='careers' element={<CareersPage />} />
            <Route path='privacy' element={<PrivacyPage />} />
          </Routes>
          <Footer />
        </Suspense>
      </div>

      {/* <div className={`${style.content} content`}>
        <Fluid className={`${location.pathname !== '/' && style.hidden}`} />

        <Header />

        <Suspense fallback={<Preloader />}>
          <Routes>
            <Route path='*' element={<HomePage />} />
            <Route path='asset-managers' element={<ManagersPage />} />
            <Route path='careers' element={<CareersPage />} />
            <Route path='privacy' element={<PrivacyPage />} />
          </Routes>
        </Suspense>
      </div>

      <Footer /> */}
    </div>
  );
}

export default App;
